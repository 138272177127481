import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";

@Component({
    selector: 'app-product-box-one',
    templateUrl: './product-box-one.component.html',
    styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit, OnChanges {
    @Input() product: any;
    @Input() type: 'subcategory' | 'product';
    @Input() categorySlug: string = null; // Parent category slug
    @Input() currency: any = this.productService.Currency; // Default Currency
    @Input() thumbnail: boolean = false; // Default False
    @Input() onHowerChangeImage: boolean = false; // Default False
    @Input() cartModal: boolean = false; // Default False
    @Input() loader: boolean = false;

    @ViewChild("quickView") QuickView: QuickViewComponent;
    @ViewChild("cartModal") CartModal: CartModalComponent;

    public ImageSrc: string
    productRouteUrl: string;

    constructor(private productService: ProductService) { }

    ngOnInit(): void {
        if (this.loader) {
            setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateProductRoute();
    }

    private updateProductRoute(): void {
        if (this.type === 'subcategory') {
            this.productRouteUrl = `/shop/categories/${this.categorySlug}/${this.product.slug}`;
        } else if (this.type === 'product') {
            this.productRouteUrl = `/product/${this.product.slug}`;
        } else {
            this.productRouteUrl = '/';
        }
    }

    // Get Product Color
    Color(variants) {
        const uniqColor = [];
        for (let i = 0; i < Object.keys(variants).length; i++) {
            if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
                uniqColor.push(variants[i].color)
            }
        }
        return uniqColor
    }

    // Change Variants
    ChangeVariants(color, product) {
        product.variants.map((item) => {
            if (item.color === color) {
                product.images.map((img) => {
                    if (img.image_id === item.image_id) {
                        this.ImageSrc = img.src;
                    }
                })
            }
        })
    }

    // Change Variants Image
    ChangeVariantsImage(src) {
        this.ImageSrc = src;
    }

    addToCart(product: any) {
        //    const cart = this.productService.addToCart(product);
        const data = { id: product.id, quantity: 1 };
        const result = this.productService.addToCart({ ...product, quantity: 1 });

        if (result) {
            console.log(`Product with ID ${product.id} added to cart successfully.`);
        } else {
            console.log(`Failed to add product with ID ${product.id} due to insufficient stock.`);
        }
    }

    addToWishlist(product: any) {
        this.productService.addToWishlist(product);
    }

    addToCompare(product: any) {
        this.productService.addToCompare(product);
    }

}
